import type { AppRouter } from '@flash-hros/bff'
import {
  getAccessToken,
  useSelectedCompany,
} from '@flash-tecnologia/hros-web-utility'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { createTRPCReact, httpBatchLink } from '@trpc/react-query'

type TRPCProviderProps = {
  children: React.ReactNode
}

export const trpc = createTRPCReact<AppRouter>()

export function TRPCProvider({ children }: TRPCProviderProps) {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        retry: (failureCount, error: any) => {
          const noRetryErrorCodes = ['NOT_FOUND']

          return (
            !noRetryErrorCodes.includes(error?.data?.code as string) &&
            failureCount < 2
          )
        },
      },
    },
  })

  const company = useSelectedCompany()

  const trpcClient = trpc.createClient({
    links: [
      httpBatchLink({
        url: `${process.env.BFF_URL}/trpc`,
        headers: async () => {
          const token = await getAccessToken()
          const companyId = company.selectedCompany.id

          return {
            Authorization: token,
            companyId,
          }
        },
      }),
    ],
  })

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </trpc.Provider>
  )
}
