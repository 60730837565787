import type { ColumnsProp } from 'src/components/Table/Grid'

import { ColumnName } from './ColumnName'

export type Assignment = {
  id: string
  name: string
  document: string
  editable: boolean
  visualized: boolean
  image: string
  value: string
  createdAt: string
}

type ColumnsConfig = {
  timeUnit: string
}

export const Columns = (config: ColumnsConfig): ColumnsProp<Assignment> => [
  {
    Header: 'Nome',
    accessor: 'name',
    Cell: ({ row }) => (
      <ColumnName
        cpf={row.original.document}
        image={row.original.image}
        name={row.original.name}
      />
    ),
    disableSortBy: true,
  },
  {
    Header: `Saldo por ${config.timeUnit} (R$)`,
    accessor: 'value',
    disableSortBy: true,
  },
  {
    Header: 'Data de atribuição',
    accessor: 'createdAt',
    disableSortBy: true,
  },
]
