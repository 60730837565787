import 'simplebar-react/dist/simplebar.min.css'
import '../src/i18n'

import { ThemeProvider } from '@flash-tecnologia/hros-web-ui-v2'

import { TRPCProvider } from './api/client'
import AppRouter from './routes'

export default function Root() {
  return (
    <TRPCProvider>
      <ThemeProvider>
        <AppRouter />
      </ThemeProvider>
    </TRPCProvider>
  )
}
